import styled from 'styled-components'
import { gray } from '../../../styles/colors'

const blue = {
  100: '#DAECFF',
  200: '#99CCF3',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  700: '#0059B2',
  900: '#003A75',
}

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
}

export const SearchBarInput = styled.input`
  height: 40px;
  padding: 6px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 8px;
`

export const Root = styled.div`
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  border-radius: 8px;
  color: ${grey[500]};
  background: ${'#fff'};
  border: 1px solid ${grey[200]};
  box-shadow: 0px 2px 4px ${'rgba(0,0,0, 0.05)'};
  display: flex;
  gap: 5px;
  padding-right: 5px;
  overflow: hidden;
  width: 320px;

  &.Mui-focused {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${blue[200]};
  }

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus-visible {
    outline: 0;
  }

  animation: 0.4s ease-out 0s 1 slideInFromTop;
  @keyframes slideInFromTop {
    0% {
      transform: translateY(-10%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
`

export const Input = styled.input`
  font-size: 0.875rem;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.5;
  color: ${grey[900]};
  background: inherit;
  border: none;
  border-radius: inherit;
  padding: 8px 12px;
  outline: 0;
  flex: 1 0 auto;
`

export const Listbox = styled.ul`
font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 6px;
  margin: 12px 0;
  width: 320px;
  border-radius: 12px;
  overflow: auto;
  outline: 0px;
  max-height: 300px;
  z-index: 1;
  position: absolute;
  background: ${'#fff'};
  border: 1px solid ${grey[200]};
  color: ${grey[900]};
  box-shadow: 0px 2px 3px ${'rgba(0,0,0, 0.05)'};
`

export const Option = styled.li`
  list-style: none;
  padding: 8px;
  border-radius: 8px;
  cursor: default;

  &:last-of-type {
    border-bottom: none;
  }

  &:hover {
    cursor: pointer;
  }

  &[aria-selected=true] {
    background-color: ${blue[100]};
    color: ${blue[900]};
  }

  &.Mui-focused,
  &.Mui-focusVisible {
    background-color: ${grey[100]};
    color: ${grey[900]};
  }

  &.Mui-focusVisible {
    box-shadow: 0 0 0 3px ${blue[200]};
  }

  &[aria-selected=true].Mui-focused,
  &[aria-selected=true].Mui-focusVisible {
    background-color: ${blue[100]};
    color: ${blue[900]};
  }

  .category-background {
    background-color: ${gray[400]};
    padding: 2px 4px;
    border-radius: 4px;
  }

  .is-listed-background {
    background-color: #E1F5FE;
    padding: 2px 4px;
    border-radius: 4px;
  }

  a {
    text-decoration: none;
    color: inherit;
  }
`
