import styled, { keyframes } from 'styled-components'

import breakpoints from '../../styles/breakpoints'
import { white, gray, orange } from '../../styles/colors'
import { Link } from 'gatsby'

const animateSpeed = '500ms'
const height = '570px'
const topDistance = '86px'
const topDistanceMd = '86px'
const animatedLeft = keyframes`
  from {
    left: -100%;
  }
  to {
    left: 0;
  }
`

export const Submenu = styled.div`
  align-content: space-between;
  animation-name: ${animatedLeft};
  animation-duration: ${animateSpeed};
  background-color: ${white};
  box-shadow: 0px 1px 10px rgba(0,0,0,0.1);
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  position: fixed;
  top: ${topDistance};
  transition: all ease-in-out ${animateSpeed};
  width: 320px;
  height: 100vh;
  z-index: 2;
  left: 0;
  
  @media (min-width: ${breakpoints.lg}) {
    background-color: ${gray[400]};
    transition: none;
    animation: none;
    border-radius: 4px;
    top: 51px;
    width: 260px;
    height: auto;
    overflow: hidden;
    right: 0;
    left: auto;
    position: absolute;
  }

  .drop-menu & {
    z-index: 2;
    @media (min-width: ${breakpoints.lg}) {
      top: 52px;
      border-radius: 0 0 10px 10px;
    }
  }
`

export const SubmenuContainer = styled.div`
  width: 100%;
  overflow-y: scroll;

  @media (min-width: ${breakpoints.lg}) {
    align-self: flex-start;
    flex: 0 0 100%;
    overflow-y: auto;

    > ul {
      margin: 0;
      padding: 0;
      width: 100%;
    }
  }

  @media (min-width: ${breakpoints.xl}) {
    > ul {
      padding: 0;
      width: 100%;
    }
  }
`

export const SubmenuOption = styled(Link)`
  display: flex;
  line-height: 1.35;
  transition: all 200ms ease-in-out;
  border-radius: 8px;
  border: none;
  width: 100%;
  background-color: transparent;
  outline: none !important;
  align-items: center;
  color: ${gray[750]};
  font-size: 16px;
  font-weight: 400;
  padding: 13px 20px;
  text-align: left;
  cursor: pointer;

  @media (min-width: ${breakpoints.lg}) {
    padding: 15px;
    font-size: 14px;
    margin-bottom: 0;
    border-radius: 0;
    border-bottom: 1px solid ${gray[500]};
    
    &.active, &:hover {
      background-color: ${gray[500]};
      color: ${gray[700]};
      text-decoration: none;
  
      .icon {
        fill: ${orange.base};
      }
    }
  }

  .back-menu {
    color: ${gray[700]};

    > svg.icon {
      float: left;
      margin-right: 10px;
      fill: ${orange.base};
  
      @media (min-width: ${breakpoints.lg}) {
        margin-bottom: 3px;
        margin-right: 15px;
        min-width: 25px;
        transition: all 200ms ease-in-out;
      }
    }
  }

  li:last-child & {
    border: none;
  }

  > svg {
    margin-right: 10px;
    fill: ${gray[600]};
  }
`

export const SubmenuMobileHeader = styled.header`
  border-bottom: 1px solid ${gray[500]};
  width: 100%;
  background-color: white;
  top: 0;
  z-index: 1;

  ~ ul {
    overflow-y: scroll;
    margin-top: 10px;
    height: calc(100% - 50px);
  }

  > ul {
    display: flex;
    padding: 0 20px;

    li {
      font-size: 12px;
      padding-right: 10px;

      &:not(:last-child)::after{
        content: '';
        display: inline-block;
        margin-left: 7px;
        width: 0;
        height: 0;
        border-top: 3px solid transparent;
        border-left: 6px solid ${gray[300]};
        border-bottom: 3px solid transparent;
      }
    }
  }
  h4 {
    padding: 0 20px;
    margin-bottom: 15px;
  }
`

export const SubmenuBody = styled.div`
  animation-name: ${animatedLeft};
  animation-duration: ${animateSpeed};
  background: ${white};
  border-left: 1px solid ${gray[300]};
  left: 0;
  padding: 0;
  position: fixed;
  top: ${topDistance};
  transition: all ease-in-out ${animateSpeed};
  z-index: 1;
  height: 100vh;
  width: 320px;
  overflow-y: scroll;

  @media (min-width: ${breakpoints.lg}) {
    animation-duration: 0s;
    background-color: ${white};
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(255, 255, 255, 0.5) 16%,
      rgba(255, 255, 255, 0) 100%
    );
    background-position: center right;
    background-repeat: no-repeat;
    background-size: contain;
    height: ${height};
    top: ${topDistanceMd};
    border-radius: 0 10px 10px 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 280px;
    width: calc(100% - 280px);
  
    .drop-menu & {
      border-radius: 0 0 10px 0;
    }
  }

  @media (min-width: ${breakpoints.xl}) {
    left: 300px;
    width: calc(100% - 300px);
  }
`
