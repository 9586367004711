/* eslint-disable max-len */
import * as React from 'react'
import slugify from 'slugify'
import { useAutocomplete } from '@mui/base/useAutocomplete'

import foundsSearchOptions from '../../../../static/foundsSearchOptions.json'

import * as S from './styles'

interface ISearchOption {
  name: string;
  category: string;
  slug: string;
  isListed?: boolean;
}

const options: ISearchOption[] = foundsSearchOptions

const SearchOption = ({ getOptionProps, option, index }: { getOptionProps: ({ option, index }: { option: ISearchOption; index: number }) => any; option: ISearchOption; index: number }) => {
  return (
    <S.Option key={option.name} {...getOptionProps({ option, index })}>
      <a href={`/${option.slug}`} className='d-flex align-items-center justify-content-between justify-content-lg-center justify-content-xl-between'>
        {option.name}
        <div>
          <span className='fs-10 lh-12 fw-700 text-gray-750 font-inter category-background ml-3'>{option.category}</span>
          {option.isListed && <span className='fs-10 lh-12 fw-700 text-gray-750 font-inter is-listed-background ml-2'>Fundo Listado</span>}
        </div>
      </a>
    </S.Option>
  )
}

const SearchBar = () => {
  const [ value, setValue ] = React.useState<ISearchOption | null>(null)

  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    focused,
  } = useAutocomplete({
    id: 'search-founds-input',
    options: options,
    getOptionLabel: (option: ISearchOption) => option.name,
    value,
    onChange: (event, newValue) => setValue(newValue),
  })

  return (
    <div>
      <S.Root {...getRootProps()} className={focused ? 'Mui-focused' : ''}>
        <S.Input {...getInputProps()} placeholder='Pesquise o fundo aqui...' />
      </S.Root>
      {groupedOptions.length > 0 && (
        <S.Listbox {...getListboxProps()}>
          {(groupedOptions as typeof options).map((option: ISearchOption, index: number) => (
            <SearchOption key={slugify(option.name)} getOptionProps={getOptionProps} option={option} index={index} />
          ))}
        </S.Listbox>
      )}
    </div>
  )
}

export default SearchBar
