import { Link } from 'gatsby'
import styled, { FlattenSimpleInterpolation } from 'styled-components'
import breakpoints from '../../styles/breakpoints'
import { interAsset } from '../../styles/colors'

interface IHeader {
  customHeaderStyle?: FlattenSimpleInterpolation;
}

export const Header = styled.header<IHeader>`
  left: 0;
  max-height: 126px;
  position: sticky;
  right: 0;
  top: 0;
  z-index: 2;
  background-color: ${interAsset.brand};

  &.drop-menu {
    align-items: center;
    background: ${interAsset.brand};
    box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    height: 86px;
    position: fixed;
    z-index: 10;
    
    @media (min-width: ${breakpoints.lg}) {
      height: 100px;
    }
  }

  ${(props: IHeader) => props.customHeaderStyle}

  &.bg-header {
    background: rgb(23, 39, 64);
  }

  .logo-inter-asset-header {
    width: 100%;
    width: 104px;
    height: auto;

    @media (min-width: ${breakpoints.lg}) {
      width: 126px;
      height: 70px;
    }
  }
`

export const LogoLink = styled(Link)`
  display: inline-block;
  margin-top: -5px;

  @media (min-width: ${breakpoints.lg}) {
    margin-left: 0;
  }
`
