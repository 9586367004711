import { css } from 'styled-components'
import { orange, white } from './colors'
import breakpoints from './breakpoints'

export default css`
  .modal {
    animation: fadeIn 500ms;
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1050;

    .backdrop {
      background-color: #000;
      left: 0;
      height: 100vh;
      opacity: 0.7;
      position: fixed;
      top: 0;
      width: 100%;
    }

    .content {
      display: block;
      height: 60vh;
      left: 50%;
      max-width: 1100px;
      position: absolute;
      text-align: center;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 90%;

      @media (min-width: ${breakpoints.sm}) {
        height: 533px;
        max-width: 1100px;
      }
    }

    .close-button {
      background: ${white};
      border-radius: 25px;
      cursor: pointer;
      display: block;
      height: 40px;
      position: absolute;
      right: -13px;
      text-indent: -99999px;
      top: -20px;
      width: 40px;
      &:after {
        bottom: 0;
        color: ${orange.base}; 
        content: "X";
        cursor: pointer;
        display: block;
        height: 40px;
        left: 0;
        line-height: 42px;
        position: absolute;
        text-indent: 0;
        width: 40px;
      }
    }
  }`
