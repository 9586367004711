import React from 'react'
import ThinArrow from 'inter-frontend-svgs/lib/layout/thin-arrow'
import { Submenu, SubmenuContainer, SubmenuOption, SubmenuMobileHeader } from './style'
import useDataLayerHeader from '../../hooks/useDataLayer/dataLayerHeader'

interface ISubmenu {
  windowWidth: number;
  currentItem: IMenuDataItem;
  hideMainMenu: (value: React.MouseEvent) => void;
  scrollTo: (evt: React.MouseEvent) => void;
  toggleMenu: () => void;
 }

 interface IMenuDataItem {
  title: string;
  anchor: ISubmenuItem[];
}

interface ISubmenuItem {
  title: string;
  anchor: string;
}

const WIDTH_LG = 992

const SubmenuComponent: React.FC<ISubmenu> = ({
  windowWidth,
  currentItem,
  hideMainMenu,
  scrollTo,
  toggleMenu,
}: ISubmenu): React.ReactElement => {
  const submenuDefinition = currentItem.anchor.map((itemSubMenu: ISubmenuItem) => {
    const [ sendDatalayerHeaderEvent ] = useDataLayerHeader()

    const titleSubmenu = itemSubMenu.title
    let routeTag = <></>

    if (itemSubMenu.anchor.includes('http')) {
      routeTag = (
        <SubmenuOption
          as='a'
          href={itemSubMenu.anchor}
          target='_blank'
          rel='noopener noreferrer'
          onClick={() => {
            sendDatalayerHeaderEvent({
              section: 'null',
              c_page: 'www.interasset.com.br',
              element_name: titleSubmenu,
              element_action: 'click button',
              redirect_url: itemSubMenu.anchor,
            })
          }}
        >
          {titleSubmenu}
        </SubmenuOption>
      )
    } else if (itemSubMenu.anchor.includes('#')) {
      routeTag = (
        <SubmenuOption
          as='a'
          href={itemSubMenu.anchor}
          onClick={(evt: React.MouseEvent) => {
            scrollTo(evt)
            sendDatalayerHeaderEvent({
              section: 'null',
              c_page: 'www.interasset.com.br',
              element_name: titleSubmenu,
              element_action: 'click button',
              redirect_url: itemSubMenu.anchor,
            })
          }}
        >
          {titleSubmenu}
        </SubmenuOption>
      )
    } else {
      routeTag = (
        <SubmenuOption
          to={itemSubMenu.anchor}
          title={itemSubMenu.title}
          onClick={() => {
            toggleMenu()
            sendDatalayerHeaderEvent({
              section: 'null',
              c_page: 'www.interasset.com.br',
              element_name: itemSubMenu.title,
              element_action: 'click button',
              redirect_url: itemSubMenu.anchor,
            })
          }}
        >
          {titleSubmenu}
        </SubmenuOption>
      )
    }

    return (
      <li key={itemSubMenu.title}>
        {routeTag}
      </li>
    )
  })

  return (
    <Submenu>
      <SubmenuContainer>
        {
          windowWidth < WIDTH_LG &&
            (
              <SubmenuMobileHeader>
                <SubmenuOption to='javascript:void(0)' className='back-menu' onClick={(evt: React.MouseEvent) => hideMainMenu(evt)}>
                  <ThinArrow width='20' height='20' rotate='180' color='orange--base' />
                </SubmenuOption>
                <ul className='list-unstyled text-header-gray--light'>
                  <li>menu</li>
                  <li>{currentItem.title.toLowerCase()}</li>
                </ul>
                <h4>{currentItem.title}</h4>
              </SubmenuMobileHeader>
            )
        }
        <ul className='list-unstyled'>
          {submenuDefinition}
        </ul>
      </SubmenuContainer>
    </Submenu>
  )
}

export default SubmenuComponent
