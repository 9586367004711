import { css } from 'styled-components'
import breakpoints from './breakpoints'

export default css`
  .container {
    @media (min-width: ${breakpoints.xl}) {
      max-width: 1190px;
    }
  }
`
