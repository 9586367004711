import React from 'react'
import { Helmet } from 'react-helmet'

const environment = process.env.ENVIRONMENT

const CookieBanner: React.FC = () => {
  if (environment === 'development' || environment === 'staging') {
    return (
      <Helmet>
        <script type="text/javascript" src="https://cdn.cookielaw.org/consent/97d71957-14fc-4435-864b-4aa4962a1d51-test/OtAutoBlock.js" />
        <script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js" type="text/javascript" charSet="UTF-8" data-domain-script="97d71957-14fc-4435-864b-4aa4962a1d51-test" />
        <script type="text/javascript">
          function OptanonWrapper() { }
        </script>
      </Helmet>
    )
  }

  return (
    <Helmet>
      <script type="text/javascript" src="https://cdn.cookielaw.org/consent/97d71957-14fc-4435-864b-4aa4962a1d51/OtAutoBlock.js" />
      <script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js" type="text/javascript" 
        charSet="UTF-8" data-domain-script="97d71957-14fc-4435-864b-4aa4962a1d51" />
      <script type="text/javascript">
        function OptanonWrapper() { }
      </script>
    </Helmet>
  )
}

export default CookieBanner
