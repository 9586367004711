import React from 'react'
import { Helmet } from 'react-helmet'

const TagManager: React.FC = () => (
  <>
    <Helmet>
      <script>
        {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-MCF8J2J');
        `}
      </script>
      <link rel='stylesheet' href='https://cdn.bancointer.com.br/inter-ui/4.x/latest/inter-ui.css' />
    </Helmet>
    <noscript>
      <iframe
        title='tag-manager'
        src='https://www.googletagmanager.com/ns.html?id=GTM-MCF8J2J'
        height='0'
        width='0'
        style={{
          display: 'none',
          visibility: 'hidden',
        }}
      />
    </noscript>
  </>
)

export default TagManager
