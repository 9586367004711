import React, { memo, useState } from 'react'
import { FlattenSimpleInterpolation } from 'styled-components'

// Hooks
import useWidth from '../../hooks/useWidth'

import Image from '../Image'

// Components
import Menu, { IMenuDataItem } from '../Menu'

// Style
import { Header, LogoLink } from './style'
import useDataLayerHeader from '../../hooks/useDataLayer/dataLayerHeader'
import SearchBar from './SearchBar'

interface IHeader {
  customHeaderStyle?: FlattenSimpleInterpolation;
  styles?: string;
  headerFoundsList?: {
    title: string;
    anchor: IMenuDataItem[];
  };
}

const HeaderComponent: React.FC<IHeader> = ({ customHeaderStyle, styles, headerFoundsList }: IHeader) => {
  const windowWidth: number = useWidth(200)
  const [ sendDatalayerHeaderEvent ] = useDataLayerHeader()
  const [ menuActive, setMenuActive ] = useState<boolean>(false)

  return (
    <>
      <Header
        className={`
          py-3 ${styles}
          landscape`}
        customHeaderStyle={customHeaderStyle}
      >
        <div className='container'>
          <div className='row mx-0 align-items-center'>
            <LogoLink
              className='logo-link'
              to='/'
              title='Inter Asset'
              onClick={() => {
                sendDatalayerHeaderEvent({
                  section: 'null',
                  element_name: 'Logo',
                  element_action: 'click button',
                  redirect_url: 'www.interasset.com.br',
                  c_page: 'www.interasset.com.br',
                })
              }}
            >
              <Image name='LogoInterAssetNewBranding' alt='Logo Inter Asset Header' className='logo-inter-asset-header' />
              <h1 className='sr-only'>Banco Inter</h1>
            </LogoLink>
            <div className='d-flex px-md-0 flex-grow-1 justify-content-end'>
              <Menu windowWidth={windowWidth} headerFoundsList={headerFoundsList} menuActive={menuActive} setMenuActive={setMenuActive} />
              {!menuActive && <SearchBar />}
            </div>
          </div>
        </div>
      </Header>
    </>
  )
}

export default memo(HeaderComponent)
