import React, { ReactNode } from 'react'
import { FlattenSimpleInterpolation } from 'styled-components'

import GlobalStyle from '../../styles/global'

// Components
import Header from '../Header'
import Footer from '../Footer'
import TagManager from '../TagManager/TagManager'
import CookieBanner from '../CookieBanner'
import { IMenuDataItem } from '../Menu'

interface ILayout {
  children: ReactNode;
  customHeaderStyle?: FlattenSimpleInterpolation;
  styles?: string;
  headerFoundsList?: {
    title: string;
    anchor: IMenuDataItem[];
  };
}

const Layout: React.FC<ILayout> = ({ children, customHeaderStyle, styles, headerFoundsList }: ILayout) => {
  return (
    <>
      <CookieBanner />
      <GlobalStyle />
      <div id='modal-area' />
      <Header customHeaderStyle={customHeaderStyle} styles={styles} headerFoundsList={headerFoundsList} />
      <main>{children}</main>
      <TagManager />
      <Footer />
    </>
  )
}

export default Layout
