import React, { useState, useEffect } from 'react'
import Chevron from 'inter-frontend-svgs/lib/layout/chevron'

// Helpers
import convertToSlug from '../../helpers/convertToSlug'

// Components
import Submenu from '../Submenu'

// Assets
import FundsJSON from '../../../static/fundos.json'
import { ICategoria } from '../FundSlider'

// Styles
import { Menu, MenuContainer, MenuOption, MenuBurger } from './style'
import useDataLayerHeader from '../../hooks/useDataLayer/dataLayerHeader'

interface IMenu {
  windowWidth: number;
  headerFoundsList?: {
    title: string;
    anchor: IMenuDataItem[];
  };
  menuActive: boolean;
  setMenuActive: (menuActive: boolean) => void;
}

export interface IMenuDataItem {
  title: string;
  anchor: string | IMenuDataItem[];
}

export interface IMenuDataItemList {
  title: string;
  anchor: string | IMenuDataItem[];
}

interface IMenuData {
  foundsList?: IMenuDataItem;
  funds?: IMenuDataItem;
  compliance?: IMenuDataItem;
}

const WIDTH_LG = 992

const MenuComponent: React.FC<IMenu> = ({ windowWidth, headerFoundsList, menuActive, setMenuActive }: IMenu): React.ReactElement => {
  const [ mainActiveOption, setMainActiveOption ] = useState<string>('')
  const [ menuData, setMenuData ] = useState<IMenuData>({})
  const isOpen = (mainActiveOption && mainActiveOption !== '')
  const [ sendDatalayerHeaderEvent ] = useDataLayerHeader()

  useEffect(() => {
    function setFundsMenu () {
      const anchorMenu = FundsJSON
        .filter((category: ICategoria) => category.fundos.length > 0)
        .map((category: ICategoria) => {
          const title = category.primeiroNome + ' ' + category.segundoNome
          return {
            title: title,
            anchor: `/${convertToSlug(title, category.fundos[0].nome)}`,
          }
        })
      let menuFormated

      if (headerFoundsList) {
        menuFormated = {
          foundsList: headerFoundsList,
          funds: {
            title: 'Produtos',
            anchor: '/tipos-de-fundos/',
          },
          compliance: {
            title: 'Documentos',
            anchor: '/acesso-a-informacao?category=compliance',
          },
        }
      } else {
        menuFormated = {
          funds: {
            title: 'Produtos',
            anchor: '/tipos-de-fundos/',
          },
          compliance: {
            title: 'Documentos',
            anchor: '/acesso-a-informacao?category=compliance',
          },
        }
      }

      setMenuData(menuFormated)
    }

    setFundsMenu()
  }, [ headerFoundsList ])

  function openMenuSection (option: IMenuDataItem) {
    const title: string = option.title
    title === mainActiveOption ? setMainActiveOption('') : setMainActiveOption(title)
  }

  function toggleMenu () {
    setMenuActive(!menuActive)
    setMainActiveOption('')
  }

  function hideMainMenu (evt: React.MouseEvent) {
    evt.preventDefault()
    setMainActiveOption('')
    evt.stopPropagation()
  }

  function scrollTo (evt: React.MouseEvent) {
    evt.preventDefault()
    const target = evt.target as HTMLAnchorElement
    const topElement = document.getElementById(target.hash.replace('#', ''))
    if (!topElement) { return }
    const offsetTopElem = topElement.getBoundingClientRect()
    if (typeof window !== 'undefined') {
      const top = offsetTopElem.top + window.scrollY - 70
      window.scroll({
        top,
        behavior: 'smooth',
      })
      toggleMenu()
    }
  }

  const menuDefinition = Object.keys(menuData).map((item: string) => {
    const currentItem = menuData[item]
    const titleMenu = currentItem.title
    let routeTag = <></>

    if (currentItem.anchor.includes('http')) {
      routeTag = (
        <MenuOption
          href={currentItem.anchor}
          as='a'
          target='_blank'
          rel='noopener noreferrer'
          onClick={() => {
            sendDatalayerHeaderEvent({
              section: 'null',
              c_page: 'www.interasset.com.br',
              element_name: titleMenu,
              element_action: 'click button',
              redirect_url: currentItem.anchor,
            })
          }}
        >
          {titleMenu}
        </MenuOption>
      )
    } else if (currentItem.anchor.includes('#')) {
      routeTag = (
        <MenuOption
          href={currentItem.anchor}
          as='a'
          onClick={(evt: React.MouseEvent) => {
            scrollTo(evt)
            sendDatalayerHeaderEvent({
              section: 'null',
              c_page: 'www.interasset.com.br',
              element_name: titleMenu,
              element_action: 'click button',
              redirect_url: currentItem.anchor,
            })
          }}
        >
          {titleMenu}
        </MenuOption>
      )
    } else if (typeof currentItem.anchor === 'object') {
      const isActive = (mainActiveOption === currentItem.title)
      routeTag = (
        <>
          <MenuOption
            href='#'
            as='a'
            active={isActive}
            onClick={(evt: React.MouseEvent<HTMLAnchorElement>) => {
              evt.preventDefault(); openMenuSection(currentItem)
              sendDatalayerHeaderEvent({
                section: 'null',
                c_page: 'www.interasset.com.br',
                element_name: titleMenu,
                element_action: 'click button',
                redirect_url: '#',
              })
            }}
          >
            {titleMenu}
            <span className='arrow'>
              { typeof window !== 'undefined' && windowWidth < WIDTH_LG ? <Chevron width='10' height='6' rotate='270' />
                : <Chevron width='10' height='6' rotate='0' /> }
            </span>
          </MenuOption>
          {isActive &&
            <Submenu
              windowWidth={windowWidth}
              currentItem={currentItem}
              hideMainMenu={hideMainMenu}
              scrollTo={scrollTo}
              toggleMenu={toggleMenu}
            />
          }
        </>
      )
    } else {
      routeTag = (
        <MenuOption
          to={currentItem.anchor}
          title={currentItem.title}
          onClick={() => {
            sendDatalayerHeaderEvent({
              section: 'null',
              c_page: 'www.interasset.com.br',
              element_name: titleMenu,
              element_action: 'click button',
              redirect_url: currentItem.anchor,
            })
          }}
        >
          {titleMenu}
        </MenuOption>
      )
    }

    return (
      <li className='position-relative' key={item}>
        {routeTag}
      </li>
    )
  })

  return (
    <Menu active={menuActive}>
      {typeof window !== 'undefined' && windowWidth < WIDTH_LG &&
        (
          <MenuBurger onClick={toggleMenu} aria-label='menu'>
            <span />
            <span />
            <span />
          </MenuBurger>
        )
      }
      <MenuContainer className='list-unstyled'>
        {menuDefinition}
      </MenuContainer>
      {isOpen && <div className='backdrop-area' onClick={(evt: React.MouseEvent<HTMLDivElement>) => hideMainMenu(evt)} />}
    </Menu>
  )
}

export default MenuComponent
