import styled from 'styled-components'
import { gray, interAsset, orange } from '../../styles/colors'
import breakpoints from '../../styles/breakpoints'

export const Footer = styled.footer`
  background: ${interAsset.brand};
  height: 320px;

  @media ${breakpoints.md} {
    height: 248px;
  }

  .logo-inter-asset-footer {
    width: 100%;
    width: 147px;
    height: auto;

    @media (min-width: ${breakpoints.md}) {
      width: 126px;
      height: 70px;
    }
  }

  .icons-div {
    a {
      margin-right: 16px;
      @media (min-width: ${breakpoints.md}) {
        margin-right: 24px;
      }
    }
    a:last-child {
      margin-right: 0;
    }
  }

  a {
    svg {
      @media (min-width: ${breakpoints.md}) {
      }
      &:hover {
        fill: ${orange.dark};
      }
    }
  }
`

export const GrayBar = styled.hr`
  height: 2px;
  width: 100%;
  background-color: ${gray[650]};
  border-radius: 16px;

  @media (min-width: ${breakpoints.md}) {
    height: 74px;
    width: 3px;
  }
`
