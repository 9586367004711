import styled, { css, keyframes } from 'styled-components'
import breakpoints from '../../styles/breakpoints'
import { white, gray } from '../../styles/colors'
import { Link } from 'gatsby'

const animateSpeed = '500ms'
const topDistance = '86px'
const dropMenu = keyframes`
  from {
    max-height: 0;
  }
  to {
    max-height: 100%;
  }
`

interface IMenu {
  active?: boolean;
}

interface IMenuOption {
  active?: boolean;
}

export const MenuOption = styled(Link)<IMenuOption>`
  font-weight: 400;
  text-transform: none;
  transition: all 200ms ease-in-out;
  font-size: 16px;
  border: none;
  background-color: transparent;
  outline: none !important;
  color: ${gray[700]};
  align-items: center;
  display: flex;
  padding: 13px 20px;
  text-align: left;

  @media (min-width: ${breakpoints.lg}) {
    color: ${white};
    margin: 0 2px;
    padding: 5px 15px;
    position: relative;

    .drop-menu & {
      color: ${white} !important;

      &:hover {
        color: #ffffff;
        background-color: rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        text-decoration: none;
      }
    }

    ${(props: IMenuOption) => props.active && css`
      .drop-menu & {
        background-color: rgba(0, 0, 0, 0.15);
        border-radius: 8px;
      }
      .arrow {
        transform: rotate(180deg);
      }
    `}
  }

  @media (min-width: ${breakpoints.xl}) {
    padding: 5px 15px 5px 0;
  }

  > svg{
    margin-right: 10px;
    fill: ${gray[600]};
  }

  .arrow {
    display: inline-block;
    height: 20px;
    margin-left: auto;
    position: relative;
    right: 0;
    transition: all ease ${animateSpeed};

    @media (min-width: ${breakpoints.lg}) {
      height: 5px;
      line-height: 1;
      margin-left: 5px;
      margin-right: 0;
      width: 9px;
      transform-origin: center;
    }

    @media (min-width: ${breakpoints.xl}) {
      margin-left: 10px;
    }

    svg {
      fill: ${gray[700]};
      margin-right: 0;
      margin-top: -3px;

      @media (min-width: ${breakpoints.lg}) {
        fill: ${white};
        margin-top: auto;
        position: absolute;

        .drop-menu & {
          fill: ${white} !important;
        }
      }
    }
  }
`

export const MenuBurger = styled.button`
  top: 20px;
  position: fixed;
  right: 16px;
  outline: none !important;
  padding: 0;
  background-color: transparent;
  border: none;

  span{
    height: 2px;
    width: 22px;
    display: block;
    background-color: ${white};
    border-radius: 2px;
    transition: ${animateSpeed} all cubic-bezier(0.68, -0.55, 0.27, 1.55);

    .drop-menu &{
      background-color: ${white} !important;
    }

    &:nth-child(2) {
      margin: 5px 0;
    }
  }
`

export const MenuContainer = styled.ul`
  background-color: white;
  box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.1);
  left: -100%;
  margin: 0;
  overflow-y: scroll;
  padding: 0;
  width: 320px;
  position: fixed;
  top: ${topDistance};
  height: calc(100vh - 50px);
  overflow-y: scroll;
  animation-name: ${dropMenu};
  animation-duration: ${animateSpeed};
  transition: all ease-in-out ${animateSpeed};

  @media (min-width: ${breakpoints.lg}) {
    background-color: transparent;
    box-shadow: none;
    height: auto;
    overflow: initial;
    position: initial;
    right: 0;
    text-align: right;
    top: inherit;
    width: initial;
    left: 0;

    .drop-menu & {
      position: static;
    }
  }
  
  li {
    text-align: left;
  }

  > li {

    @media (min-width: ${breakpoints.lg}) {
      display: inline-block;
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }     
  }

  p {
    font-weight: 400;
    line-height: 1.35;
    margin-bottom: 0;
    text-transform: none;
  }
`

export const Menu = styled.nav<IMenu>`

  .backdrop-area {
    background-color: rgba(255, 255, 255, 0);
    display: block;
    height: 100vh;
    left: 0px;
    position: fixed;
    width: 100%;
    z-index: -1;
  }

  ${(props: IMenu) => {
    if (props.active) {
      return (
        css`
          &::after{
            content: '';
            top: 86px;
            right: 0;
            left: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.5);
            display: block;
            height: 100vh;
            z-index: -1;
            position: absolute;

            @media (min-width: ${breakpoints.lg}) {
              display: none;
            }
          }

          ${MenuContainer} {
            left: 0;
          }

          ${MenuBurger} {
            span {
              &:first-child{
                transform: rotate(-45deg);
                position: absolute;
                bottom: -10px;
                left: -23px;
              }
              &:nth-child(2) {
                display: none;
              }
              &:last-child{
                transform: rotate(45deg);
                position: absolute;
                bottom: -10px;
                left: -23px;
              }
            }
          }
        `
      )
    }
  }}
`
