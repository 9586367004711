import { createGlobalStyle } from 'styled-components'
import reset from './reset'
import grid from './grid'
import { white, gray, orange, brown, green, blue, interAsset, generateColorClasses } from './colors'
import { generateFontSizeClasses, generateFontWeightClasses, generateLineHeightClasses } from './fonts'
import { generateBorderRadiusClasses } from './radius'

import '../sass/bootstrap.scss'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import modal from './modalVideo'

const globalStyle = createGlobalStyle`
  ${reset}
  ${grid}
  ${modal}

  .fill-white {
    fill: ${white};
  };

  ${generateColorClasses(orange, 'orange')}
  ${generateColorClasses(gray, 'gray')}
  ${generateColorClasses(brown, 'brown')}
  ${generateColorClasses(green, 'green')}
  ${generateColorClasses(blue, 'blue')}
  ${generateColorClasses(interAsset, 'interasset')}
  ${generateFontWeightClasses()}
  ${generateFontSizeClasses()}
  ${generateLineHeightClasses()}
  ${generateBorderRadiusClasses()}
`

export default globalStyle
